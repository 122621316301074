import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Вітаємо на курсах "Шеф за 30 днів”
			</title>
			<meta name={"description"} content={"Освоюй мистецтво кулінарії – Кожен може стати справжнім шефом!"} />
			<meta property={"og:title"} content={"Вітаємо на курсах \"Шеф за 30 днів”"} />
			<meta property={"og:description"} content={"Освоюй мистецтво кулінарії – Кожен може стати справжнім шефом!"} />
			<meta property={"og:image"} content={"https://qivexsale.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://qivexsale.com/img/4047768.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qivexsale.com/img/4047768.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qivexsale.com/img/4047768.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qivexsale.com/img/4047768.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qivexsale.com/img/4047768.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qivexsale.com/img/4047768.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text margin="0px 0px 24px 0px" color="--dark" font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif" lg-text-align="center">
					"Шеф за 30 днів”
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Ваш шлях до вершин кулінарної майстерності починається тут! Наші онлайн курси дозволять вам зануритись у світ вишуканих страв і професійних технік приготування, не виходячи з дому. Разом з нами ви відкриєте секрети шеф-кухарів і навчитеся створювати кулінарні шедеври.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://qivexsale.com/img/1.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://qivexsale.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Чому обирають нас?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Індивідуальний підхід: Курси адаптовані під кожного, від новачка до досвідченого гурмана.
					<br /><br />
					Гнучкий графік: Навчайтесь у власному темпі, в будь-який зручний для вас час.
					<br /><br />
					Живе спілкування: Прямі ефіри з шеф-кухарями, де ви маєте можливість поставити власні питання та отримати професійну пораду.
					<br /><br />
					Доступність: Все, що вам потрібно – це підключення до інтернету, щоб розпочати свою кулінарну пригоду.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 130px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 64px 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					width="75%"
					lg-width="100%"
					lg-text-align="center"
				>
					Зв'яжіться з нами
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
					Ми завжди відкриті до ваших питань та побажань.
						<br />
						<br />
						Телефон: 097 444 9707
						<br />
						Електронна адреса: contact@qivexsale.com
					</Text>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
					Шеф за 30 днів – Відкрий у собі шефа!
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});